import React from 'react';
import Recaptcha from 'react-recaptcha';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@ui/button';
import { Heading, Input } from '@web/atoms';
import { schema } from './schema';
import {
  PasswordResetContainer,
  RecaptchaContainer,
  PasswordResetForm,
  ButtonContainer,
  Subheading,
  PasswordResetFormContainer,
  HeadingContainer,
  InputContainer,
} from './styles';

export const PasswordReset = ({
  siteKey,
  heading,
  labelEmail,
  labelSendLink,
  onSubmit,
}) => {
  const { control, handleSubmit, formState, setValue } = useForm({
    defaultValues: {
      email: '',
      captchaToken: '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });

  const verifyCallback = token => {
    setValue('captchaToken', token, { shouldValidate: true });
  };

  const expiredCallback = () => {
    setValue('captchaToken', '', { shouldValidate: true });
  };

  return (
    <PasswordResetContainer>
      <PasswordResetFormContainer>
        <PasswordResetForm onSubmit={handleSubmit(onSubmit)}>
          <HeadingContainer>
            <Heading
              headingText={heading}
              headingLevel="h2"
              typography="heading2"
              position="center"
              backgroundColor="background.lightest"
            />
          </HeadingContainer>
          <Subheading>
            We'll send you instructions for a new password!
          </Subheading>
          <InputContainer>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  labelText={labelEmail}
                  status={fieldState.error ? 'error' : null}
                  error={fieldState.error?.message || ''}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <RecaptchaContainer>
              <Recaptcha
                elementID="forgotCaptcha"
                render="explicit"
                // This is needed by a bad conditional in the lib
                // TODO(James) migrate, this lib is bad
                onloadCallback={() => {}}
                sitekey={siteKey}
                verifyCallback={verifyCallback}
                expiredCallback={expiredCallback}
              />
            </RecaptchaContainer>
          </InputContainer>
          <ButtonContainer>
            <Button type="submit" disabled={!formState.isValid}>
              {labelSendLink}
            </Button>
          </ButtonContainer>
        </PasswordResetForm>
      </PasswordResetFormContainer>
    </PasswordResetContainer>
  );
};

PasswordReset.displayName = 'PasswordReset';
PasswordReset.propTypes = {
  siteKey: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  labelEmail: PropTypes.string.isRequired,
  labelSendLink: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
