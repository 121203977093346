'use client';
import styled from 'styled-components';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${props => props.theme.spacing(14, 6)};
`;

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 650px;
  padding: ${props => props.theme.spacing(0, 6)};
  border-radius: 35px;
  margin-bottom: ${props => props.theme.spacing(8)};
  background-color: ${props => props.theme.colors.background.lightest};
`;

export const HeadingContainer = styled.div`
  margin-bottom: ${props => props.theme.spacing(7)};
  margin-top: ${props => props.theme.spacing(12)};
`;

export const Form = styled.form`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 512px;

  > div {
    width: inherit;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${props => props.theme.spacing(8)};
  margin-bottom: ${props => props.theme.spacing(12)};
  max-width: 336px;
`;

export const InputContainer = styled.div`
  padding-bottom: 24px;
`;

export const LinkContainer = styled.div`
  margin-top: ${props => props.theme.spacing(6)};

  a {
    color: ${props => props.theme.colors.secondary.chia};
  }
`;
