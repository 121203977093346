import * as yup from 'yup';

export const newPasswordSchema = yup.object().shape({
  new_password: yup
    .string()
    .trim()
    .required('New password is required')
    .matches(
      /^.*(?=.{8,})((?=.*[^a-zA-Z0-9]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      'Password must contain at least 8 characters, one uppercase, one number and one special character.',
    ),
  confirm_password: yup
    .string()
    .trim()
    .required('Confirm password is required')
    .oneOf([yup.ref('new_password'), null], 'New password fields must match.'),
});
