'use client';
import React, { useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { LOGGEDOUT_LINKS } from '@web/molecules/Header/constants';
import { Footer } from '@web/components';
import {
  BaseHeader,
  DiscountBanner,
  LeftMenuLinks,
  CenterMenuLinks,
  LoginMenuLinks,
} from '@web/molecules';
import { LoginForm } from '../LoginForm';
import { useReporter } from '../../reporter';
import { FOOTER_CONTENT } from '../../testing/constants';
import { useSessionCustomer } from '../../customer';
import { Redirect } from '../../router';
import { useToast } from '../../Toast';
import { ApiResult, useSession } from '../../api';
import { onLoginSubmitted, onLoginSuccessful } from './events';
import { LoginProps, NewToOllieProps } from './constants';
import { StyledLoginContainer } from './styles';

export const LoginContainer = () => {
  const { isActive, getCustomer } = useSessionCustomer();
  const { showToast } = useToast();
  const { login, service: sessionService } = useSession();
  const reporter = useReporter();
  const router = useRouter();
  const searchParams = useSearchParams();
  const next = searchParams?.get('next');
  const goToOnboarding = () => {
    router.push(`/onboarding/`);
    showToast({
      status: 'info',
      message: 'Complete the quiz and checkout to finish creating your account',
    });
  };

  let cleanedNext = next?.includes('/account/account') ? '/account/' : next;

  //Validate cleanedNext
  if (typeof window !== 'undefined') {
    const ourURL = new URL(window.location.href).hostname;
    const validUrlRegex = new RegExp(
      /^(http(s):\/\/.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
    );
    //Checks if the 'next' query param is a valid URL
    const isValidUrl = validUrlRegex.test(cleanedNext);
    //If it's a vlaid URL, but is not within our domain: ignore it so the user logs into their account
    if (isValidUrl && !cleanedNext.includes(ourURL)) cleanedNext = undefined;
  }

  const onSubmit = async values => {
    const callbackUrl = `${window.location.origin}${
      cleanedNext ?? '/account/'
    }`;
    reporter.tag(onLoginSubmitted());
    const loginResult = await ApiResult.callAsync(
      async () => await login(values.email, values.password),
    );
    ApiResult.match(loginResult, {
      success: async () => {
        reporter.tag(onLoginSuccessful());
        const dataResponse = await getCustomer(sessionService.user.id);
        if (dataResponse.data.status === 'lead') {
          goToOnboarding();
        } else {
          router.push(callbackUrl);
        }
      },
      error: {
        tooManyFailedLogin: () => {
          showToast({
            status: 'error',
            headline: 'Login Failed',
            message:
              'Too many failed login attempts. Please try again in 10 minutes',
          });
        },
        client: () => {
          showToast({
            status: 'error',
            headline: 'Login Failed',
            message: 'Please check your email & password and try again.',
          });
        },
      },
    });
    return loginResult;
  };

  useEffect(() => {
    if (next?.includes('/account/account')) {
      reporter.error(new Error('next url has value with repeated account'));
    }
  }, [next, reporter]);

  if (isActive) {
    return <Redirect to={cleanedNext ?? '/account/'} />;
  }
  return (
    <StyledLoginContainer>
      <BaseHeader
        leftSegment={<LeftMenuLinks links={LOGGEDOUT_LINKS} />}
        topSegment={<DiscountBanner />}
        rightSegment={<LoginMenuLinks />}
        centerSegment={<CenterMenuLinks links={LOGGEDOUT_LINKS} />}
        logoUrl="/"
        logoSrText="Ollie logo"
        variant="white"
      />
      <LoginForm
        {...LoginProps}
        newToOllie={NewToOllieProps}
        onSubmit={onSubmit}
      />
      <Footer {...FOOTER_CONTENT} />
    </StyledLoginContainer>
  );
};

LoginContainer.displayName = 'LoginContainer';
