'use client';
import PropTypes from 'prop-types';
import React from 'react';
import Head from 'next/head';
import {
  BaseHeader,
  DiscountBanner,
  LeftMenuLinks,
  UserMenuLinks,
  CenterMenuLinks,
  LOGGEDOUT_LINKS,
} from '@web/molecules';
import { Footer } from '@web/components';
import { TOAST_SERVER_MESSAGE, ERROR_MESSAGE } from '../../account/constants';
import { useCustomer } from '../../customer';
import {
  useToast,
  TOAST_FORGOT_PASSWORD_TITLE,
  TOAST_FORGOT_PASSWORD_MESSAGE,
} from '../../Toast';
import { PasswordReset } from '../PasswordReset';
import { ApiResult } from '../../api';
import { FOOTER_CONTENT } from '../../testing/constants';
import { StyledForgotPasswordContainer } from './styles.js';

export const ForgotPasswordContainer = ({ sitekey }) => {
  const { forgotPassword } = useCustomer();
  const { showToast } = useToast();
  const onSubmit = async ({ email, captchaToken }) => {
    const result = await forgotPassword(email, captchaToken);
    ApiResult.match(result, {
      success: () => {
        showToast({
          status: 'success',
          message: TOAST_FORGOT_PASSWORD_MESSAGE,
          headline: TOAST_FORGOT_PASSWORD_TITLE,
        });
      },
      error: {
        server: () => {
          showToast({
            status: 'error',
            message: TOAST_SERVER_MESSAGE,
            headline: 'Error Message',
          });
        },
        client: () => {
          showToast({
            status: 'error',
            message:
              result.error?.original.response.data?.detail || ERROR_MESSAGE,
            headline: 'Error Message',
          });
        },
      },
    });
  };
  return (
    <>
      <Head>
        <script
          src="https://www.google.com/recaptcha/api.js?render=explicit"
          defer
        ></script>
      </Head>
      <BaseHeader
        leftSegment={<LeftMenuLinks links={LOGGEDOUT_LINKS} />}
        topSegment={<DiscountBanner />}
        centerSegment={<CenterMenuLinks links={LOGGEDOUT_LINKS} />}
        rightSegment={<UserMenuLinks />}
        logoUrl="/"
        logoSrText="Ollie logo"
        variant="white"
      />
      <StyledForgotPasswordContainer>
        <PasswordReset
          siteKey={sitekey}
          heading="Password Reset"
          labelEmail="Email address"
          labelSendLink="Send me a link"
          onSubmit={onSubmit}
        />
      </StyledForgotPasswordContainer>
      <Footer {...FOOTER_CONTENT} />
    </>
  );
};

ForgotPasswordContainer.displayName = 'ForgotPasswordContainer';

ForgotPasswordContainer.propTypes = {
  sitekey: PropTypes.string.isRequired,
};
