'use client';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import { ErrorText } from '../ErrorText';
import showPasswordIcon from '../../images/Icons/eye-fill.svg';
import hidePasswordIcon from '../../images/Icons/eye-slash.svg';
import {
  Container,
  Label,
  LabelContainer,
  InputContainer,
  TextInput,
  Icon,
  TogglePasswordButton,
  PasswordInfoIcon,
} from './styles.js';

export const Input = forwardRef((props, ref) => {
  const inputRef = useRef();
  const {
    labelText,
    visuallyHideLabel,
    name,
    icon,
    placeholder = labelText,
    type,
    value,
    status,
    error,
    disabled = false,
    onChange,
    onBlur,
    centeredAlign,
    maxLength,
    autocomplete,
  } = props;
  const [inputType, setInputType] = useState(type);
  const filled = value !== '';
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));
  const toggleIputType = () => {
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };

  return (
    <Container>
      <LabelContainer>
        <Label
          disabled={disabled}
          htmlFor={name}
          $visuallyHidden={visuallyHideLabel}
        >
          {labelText}
          {type === 'password' && icon ? (
            <PasswordInfoIcon>{icon}</PasswordInfoIcon>
          ) : (
            ''
          )}
        </Label>
        <InputContainer>
          <TextInput
            type={inputType}
            placeholder={placeholder}
            autoComplete={autocomplete}
            $status={status}
            value={value}
            disabled={disabled}
            id={name}
            filled={filled}
            onChange={event => {
              onChange(event.target.value);
            }}
            name={name}
            onBlur={onBlur}
            ref={inputRef}
            $centeredAlign={centeredAlign}
            maxLength={maxLength}
          />
          {type === 'password' ? (
            <TogglePasswordButton
              onClick={toggleIputType}
              role="switch"
              type="button"
              aria-checked={inputType === 'password' ? 'false' : 'true'}
            >
              <Image
                alt={'Show password'}
                src={
                  inputType === 'password' ? hidePasswordIcon : showPasswordIcon
                }
              ></Image>
            </TogglePasswordButton>
          ) : (
            <Icon>{icon}</Icon>
          )}
        </InputContainer>
      </LabelContainer>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
});

Input.displayName = 'Input';

Input.propTypes = {
  labelText: PropTypes.string.isRequired,
  visuallyHideLabel: PropTypes.bool,
  // Could be a tooltip component or a plain old icon
  icon: PropTypes.node,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password']),
  autocomplete: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.oneOf(['error', 'disabled']),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  centeredAlign: PropTypes.bool,
};
