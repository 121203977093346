'use client';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '@ui/button';
import { Input, SmolLink, Heading } from '@web/atoms';
import { useReporter } from '../../reporter';
import { ApiResult } from '../../api';
import {
  LoginContainer,
  LoginFormContainer,
  Form,
  ButtonContainer,
  LinkContainer,
  HeadingContainer,
  InputContainer,
} from './styles.js';
import { NewToOllie } from './NewToOllie';
import { onLoginView } from './events';
import { schema } from './schema';

export const LoginForm = ({
  heading,
  labelEmail,
  labelPassword,
  onSubmit,
  placeholderEmail,
  placeholderPassword,
  labelLogin,
  forgotPasswordUrl,
  forgotPasswordLabel,
  newToOllie,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isSubmitted },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
    mode: 'onTouched',
  });
  const isFormSubmitting = isSubmitting || isSubmitted;
  const reporter = useReporter();

  useEffect(() => {
    reporter.tag(onLoginView());
  }, [reporter]);

  return (
    <LoginContainer>
      <LoginFormContainer>
        <HeadingContainer>
          <Heading
            headingText={heading}
            headingLevel="h2"
            typography="heading2"
            backgroundColor="background.lightest"
          />
        </HeadingContainer>
        <Form
          method="post"
          onSubmit={handleSubmit(async data => {
            const result = await onSubmit(data);
            ApiResult.ifError(result, () => {
              setTimeout(() => {
                reset(data, {
                  keepIsSubmitted: false,
                });
              });
            });
          })}
        >
          <InputContainer>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  labelText={labelEmail}
                  status={fieldState.error ? 'error' : null}
                  error={fieldState.error?.message || ''}
                  placeholder={placeholderEmail || ''}
                />
              )}
            />
          </InputContainer>
          <InputContainer>
            <Controller
              control={control}
              name="password"
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  type="password"
                  status={fieldState.error ? 'error' : null}
                  error={fieldState.error?.message || ''}
                  labelText={labelPassword}
                  placeholder={placeholderPassword || ''}
                />
              )}
            />
          </InputContainer>
          <ButtonContainer>
            <Button
              type="submit"
              fullWidth
              disabled={isFormSubmitting}
              isLoading={isFormSubmitting}
            >
              {labelLogin}
            </Button>
            <LinkContainer>
              <SmolLink href={forgotPasswordUrl}>
                {forgotPasswordLabel}
              </SmolLink>
            </LinkContainer>
          </ButtonContainer>
        </Form>
      </LoginFormContainer>
      <NewToOllie {...newToOllie} />
    </LoginContainer>
  );
};

LoginForm.displayName = 'Login';
LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  heading: PropTypes.string.isRequired,
  labelEmail: PropTypes.string.isRequired,
  labelPassword: PropTypes.string.isRequired,
  labelLogin: PropTypes.string.isRequired,
  forgotPasswordUrl: PropTypes.string.isRequired,
  forgotPasswordLabel: PropTypes.string.isRequired,
  placeholderEmail: PropTypes.string,
  placeholderPassword: PropTypes.string,
  newToOllie: PropTypes.shape({
    subheading: PropTypes.string.isRequired,
    img: PropTypes.object.isRequired,
    heading: PropTypes.string.isRequired,
    getStartedText: PropTypes.string.isRequired,
    getStartedUrl: PropTypes.string.isRequired,
  }),
};
