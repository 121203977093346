'use client';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { ErrorText } from '../ErrorText';
import { useReporter } from '../../reporter';
import {
  Container,
  Label,
  Icon,
  StyledCardNumberElement,
  StyledCardExpiryElement,
  StyledCardCvcElement,
} from './styles.js';

const stripeStyles = theme => ({
  style: {
    base: {
      color: theme.colors.text.primary,
      fontSize: '1rem',
      '::placeholder': {
        color: '#5E584E66',
      },
    },
    invalid: {
      color: theme.colors.text.primary,
      fontSize: '1rem',
    },
  },
});

const types = {
  cardNumber: {
    component: StyledCardNumberElement,
    validValue: '4242424242424242',
    invalidValue: '',
  },
  cardExpiry: {
    component: StyledCardExpiryElement,
    validValue: '10/99',
    invalidValue: '',
  },
  cardCvc: {
    component: StyledCardCvcElement,
    validValue: '333',
    invalidValue: '',
  },
};

export const StripeInput = forwardRef((props, ref) => {
  const inputRef = useRef();
  const theme = useTheme();
  const [onFocusState, setOnFocusState] = useState(false);
  const [empty, setEmpty] = useState(true);
  const [errorState, setErrorState] = useState();
  const reporter = useReporter();

  const {
    labelText,
    name,
    icon,
    type,
    status,
    error,
    disabled = false,
    onChange,
    onBlur,
    onFocus,
    placeholder,
  } = props;

  const handleOnFocus = () => {
    setOnFocusState(true);
    onFocus?.();
  };

  const handleOnBlur = () => {
    setOnFocusState(false);
    onBlur?.({ name, error: errorState });
  };

  const handleOnChange = event => {
    setEmpty(event.empty);
    setErrorState(event.error?.message);
    const value =
      event.error || event.empty
        ? types[type].invalidValue
        : types[type].validValue;
    onChange?.({ value, error: event.error, name });
  };

  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  const filled = !empty || onFocusState;
  const inputProps = {
    onBlur: handleOnBlur,
    onChange: handleOnChange,
    onFocus: handleOnFocus,
    status,
    filled,
    onReady: element => {
      inputRef.current = element;
      reporter.breadcrumb(`Stripe element ${type} is ready`);
    },
    options: {
      placeholder: placeholder || labelText,
      disabled,
      ...stripeStyles(theme),
    },
  };

  return (
    <Container isStripe>
      <Label filled={filled} htmlFor={name}>
        {labelText}
      </Label>
      {React.createElement(types[type].component, inputProps)}
      <Icon>{icon}</Icon>
      {error && <ErrorText>{error}</ErrorText>}
    </Container>
  );
});

StripeInput.displayName = 'StripeInput';

StripeInput.propTypes = {
  labelText: PropTypes.string.isRequired,
  // Could be a tooltip component or a plain old icon
  icon: PropTypes.node,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['cardNumber', 'cardExpiry', 'cardCvc']),
  status: PropTypes.oneOf(['error', 'disabled']),
  error: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
