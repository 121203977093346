'use client';
import styled, { css } from 'styled-components';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  align-items: ${props => (props.isStripe ? '' : 'center')};
`;
export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;
export const Label = styled.label`
  display: inline-flex;
  align-items: center;
  ${props =>
    props.$visuallyHidden
      ? props.theme.visuallyHidden
      : css`
          color: ${props => props.theme.colors.text.secondary};
          ${props => props.theme.typography.body};
          padding-bottom: ${props => props.theme.spacing(2)};
        `};
`;

const baseInputStyles = css`
  color: ${props => props.theme.colors.text.primary};
  background: ${props => props.theme.colors.background.lightest};
  font-size: 1rem;
  padding: ${props => props.theme.spacing(4)};
  border: 1px solid;
  border-color: ${props =>
    props.$status !== 'error'
      ? props.theme.colors.border.dark
      : props.theme.colors.status.errorPrimary};
  border-radius: 4px;
  width: 100%;

  /* REMOVE ARROWS ON NUMBER */
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    /* stylelint-disable-next-line */
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  &[type='number'] {
    /* stylelint-disable-next-line */
    -moz-appearance: textfield;
  }

  &:disabled {
    border-color: ${props => props.theme.colors.border.fur};
    background: ${props => props.theme.colors.background.disabled};
    color: ${props => props.theme.colors.text.tertiary};
    & ::placeholder {
      color: ${props => props.theme.colors.text.tertiary};
    }
    & + ${Label} {
      color: ${props => props.theme.colors.border.fur};
    }
  }

  &::placeholder {
    color: ${props => props.theme.colors.text.tertiary};
    opacity: 1;
  }

  &:hover&:enabled,
  &:focus,
  &.StripeElement--focus,
  &.StripeElement:hover {
    border: 2px solid
      ${props =>
        props.$status !== 'error'
          ? props.theme.colors.hero.spinach
          : props.theme.colors.status.errorPrimary};
    outline: none;
    padding: 15px;
  }
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const TextInput = styled.input`
  ${baseInputStyles}
  ${props =>
    props.$centeredAlign &&
    css`
      text-align: center;
    `}
    &::-webkit-credentials-auto-fill-button {
    margin-right: ${props =>
      props.type === 'password' ? props.theme.spacing(10) : ''};
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: ${props => props.theme.spacing(3)};
  top: 50%;
  transform: translateY(-42%);
`;

export const PasswordInfoIcon = styled.div`
  padding-left: ${props => props.theme.spacing(1)};
  padding-bottom: ${props => props.theme.spacing(1)};
`;

export const TogglePasswordButton = styled.button`
  position: absolute;
  right: ${props => props.theme.spacing(3)};
  top: 50%;
  transform: translateY(-42%);
  background-color: transparent;
  border: none;
  &:focus-visible {
    border: solid;
    border-width: 1px;
  }
`;

export const StyledCardNumberElement = styled(CardNumberElement)`
  ${baseInputStyles}
`;

export const StyledCardExpiryElement = styled(CardExpiryElement)`
  ${baseInputStyles}
`;

export const StyledCardCvcElement = styled(CardCvcElement)`
  ${baseInputStyles}
`;
