import React from 'react';
import PropTypes from 'prop-types';
import { LinkButton } from '@ui/button';
import { ResponsiveImage } from '@web/molecules';
import {
  NewToOllieContainer,
  Heading,
  Text,
  LinkButtonWrapper,
} from './styles.js';

export const NewToOllie = ({
  heading,
  subheading,
  img,
  getStartedUrl,
  getStartedText,
}) => {
  return (
    <NewToOllieContainer>
      <ResponsiveImage src={img.src} alt={img.alt} height={87} width={110} />
      <Heading>{heading}</Heading>
      <Text>{subheading}</Text>
      <LinkButtonWrapper>
        <LinkButton
          href={getStartedUrl}
          variant="spinach"
          verticalPadding={4}
          fullWidth
        >
          {getStartedText}
        </LinkButton>
      </LinkButtonWrapper>
    </NewToOllieContainer>
  );
};

NewToOllie.displayName = 'NewToOllie';
NewToOllie.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string.isRequired,
  img: PropTypes.shape({
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
  getStartedText: PropTypes.string.isRequired,
  getStartedUrl: PropTypes.string.isRequired,
};
