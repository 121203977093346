'use client';
import styled from 'styled-components';

export const NewToOllieContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  width: 100%;
  max-width: 650px;
  border-radius: 35px;
  padding: ${props => props.theme.spacing(8, 6)};
`;

export const Heading = styled.h2`
  ${props => props.theme.typography.heading2};
  margin-top: ${props => props.theme.spacing(8)};
  font-weight: normal;
`;

export const Text = styled.p`
  margin-top: ${props => props.theme.spacing(1)};
  margin-bottom: ${props => props.theme.spacing(6)};
`;

export const LinkButtonWrapper = styled.div`
  width: 100%;
  max-width: 336px;
`;
