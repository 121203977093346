import * as yup from 'yup';

export const schema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required('Email is required')
    .email('Invalid email address'),
  captchaToken: yup.string().required(),
});
