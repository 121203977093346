'use client';
import styled from 'styled-components';

export const StyledForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${props => props.theme.colors.background.base};
  padding: 56px 24px;
`;
