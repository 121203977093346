import NewOllieBox from '../LoginForm/NewToOllie/new-ollie-box.svg';

export const LoginProps = {
  heading: 'Log in',
  labelEmail: 'Email address',
  labelPassword: 'Password',
  labelLogin: 'Log in',
  placeholderEmail: 'yourname@email.com',
  forgotPasswordUrl: '/auth/forgot-password/',
  forgotPasswordLabel: 'Forgot Password?',
};

export const NewToOllieProps = {
  heading: 'New to Ollie?',
  subheading: 'Get started by setting up an account now.',
  img: {
    src: NewOllieBox,
    alt: 'New to Ollie?',
  },
  getStartedUrl: '/onboarding/',
  getStartedText: 'Get Started',
};

export const URL_TOO_MANY_FAILED_LOGIN_ERROR =
  'http://local-www.myollie.com/api/auth/error?error=TooManyFailedLoginAttempts&provider=login';

export const URL_FAILED_LOGIN_ERROR =
  'http://local-www.myollie.com/api/auth/error?error=FailedLogin&provider=login';
